<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center">
      <v-btn
        v-if="canBeSync"
        rounded
        justify-center
        color="primary"
        @click="syncUser()"
        v-ripple
        :loading="isWorking"
      >
        <v-icon class="mr-2">{{$icon('i.Refresh')}}</v-icon>
        <span>{{$t('t.SyncAr24')}}</span>
      </v-btn>
      <div v-if="canBeSync">
        <v-icon
          class="mb-1 ml-4"
          color="error"
        >{{$icon('i.Alert')}}</v-icon>
        <span class="ml-2 title error--text"> {{computedTextSync}}</span>
        <v-spacer />
        <v-btn
          v-if="!isConfirmed && isSync"
          class="mt-4 ml-4"
          small
          rounded
          justify-center
          @click="resendConfirmationEmail()"
          v-ripple
          :loading="isWorking"
        >
          <v-icon class="mr-2">{{$icon('i.MessagesOutbox')}}</v-icon>
          <span>{{$t('t.ResendConfirmationEmail')}}</span>
        </v-btn>
      </div>
      <div v-else-if="isConfirmed && isSync">
        <v-icon
          class="mb-1 ml-4"
          color="success"
        >{{$icon('i.Checked')}}</v-icon>
        <span class="ml-2 title success--text"> {{$t('t.Ar24IsActive')}}</span>
      </div>
    </div>
    <div class="d-flex flex-column mt-8">
      <div class="d-flex flex-row gap">
        <v-text-field
          class="field"
          :label="$t('t.Email')"
          v-model="computedEmail"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <number-field
          :number.sync="computedIdUser"
          :rules="rules"
          :label="$t('t.UserAr24Id')"
          nullable
        />
      </div>
      <div class="d-flex flex-row gap">
        <v-text-field
          class="field"
          :label="$t('t.Firstname')"
          v-model="computedFirstname"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="field"
          :label="$t('t.Lastname')"
          v-model="computedLastname"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
      </div>
      <div class="d-flex flex-row gap">
        <v-text-field
          class="field"
          :label="$t('t.Company')"
          v-model="computedCompany"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="field"
          :label="$t('t.Siret')"
          v-model="computedCompanySiret"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="field"
          :label="$t('t.TvaNumber')"
          v-model="computedCompanyTva"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
      </div>
      <div class="d-flex flex-row gap">
        <v-text-field
          class="field"
          :label="$t('t.Address1')"
          v-model="computedAddress1"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="field"
          :label="$t('t.Address2')"
          :clearable="true"
          v-model="computedAddress2"
        />
      </div>
      <div class="d-flex flex-row gap">
        <v-text-field
          class="field"
          :label="$t('t.City')"
          v-model="computedCity"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="field"
          :label="$t('t.PostalCode')"
          v-model="computedZipcode"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <country-iso-2-picker
          class="field"
          v-model="computedCountry"
          :rules="ruleRequired"
        />
      </div>
      <v-switch
        dense
        :label="$t('t.Ar24NotificationStatusChanged')"
        v-model="computedNotify"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CountryIso2Picker: () => import('@/components/country-iso2-picker'),
    NumberField: () => import('@/components/number-field')
  },
  data () {
    return {
      rules: [v => v >= 0 || this.$t('t.MustBeGreaterOrEqualTo', { value: 0 })],
      isWorking: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataAddress1: null,
      dataAddress2: null,
      dataCity: null,
      dataCompanySiret: null,
      dataCompanyTva: null,
      dataCompany: null,
      dataCountry: 'FR',
      dataEmail: null,
      dataIdUser: null,
      dataFirstname: null,
      dataLastname: null,
      dataNotify: false,
      dataZipcode: null
    }
  },
  computed: {
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    ruleRequired () {
      return [v => !!v || this.$t('t.IsRequired')]
    },
    canBeSync () {
      return !this.isNew && !this.isDirty && (!this.isConfirmed || !this.isSync)
    },
    isConfirmed () {
      return this.document?.v?.cgu && this.document?.v?.confirmed
    },
    isSync () {
      return this.document?.v?.isSync
    },
    computedAddress1: {
      get () {
        return this.dataAddress1
      },
      set (v) {
        this.dataAddress1 = v
        this.dataDocumentEmit()
      }
    },
    computedAddress2: {
      get () {
        return this.dataAddress2
      },
      set (v) {
        this.dataAddress2 = v
        this.dataDocumentEmit()
      }
    },
    computedCity: {
      get () {
        return this.dataCity
      },
      set (v) {
        this.dataCity = v
        this.dataDocumentEmit()
      }
    },
    computedCompanySiret: {
      get () {
        return this.dataCompanySiret
      },
      set (v) {
        this.dataCompanySiret = v
        this.dataDocumentEmit()
      }
    },
    computedCompanyTva: {
      get () {
        return this.dataCompanyTva
      },
      set (v) {
        this.dataCompanyTva = v
        this.dataDocumentEmit()
      }
    },
    computedCompany: {
      get () {
        return this.dataCompany
      },
      set (v) {
        this.dataCompany = v
        this.dataDocumentEmit()
      }
    },
    computedCountry: {
      get () {
        return this.dataCountry
      },
      set (v) {
        this.dataCountry = v
        this.dataDocumentEmit()
      }
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.dataDocumentEmit()
      }
    },
    computedFirstname: {
      get () {
        return this.dataFirstname
      },
      set (v) {
        this.dataFirstname = v
        this.dataDocumentEmit()
      }
    },
    computedIdUser: {
      get () {
        return this.dataIdUser
      },
      set (v) {
        this.dataIdUser = v
        this.dataDocumentEmit()
      }
    },
    computedLastname: {
      get () {
        return this.dataLastname
      },
      set (v) {
        this.dataLastname = v
        this.dataDocumentEmit()
      }
    },
    computedNotify: {
      get () {
        return this.dataNotify
      },
      set (v) {
        this.dataNotify = !!v
        this.dataDocumentEmit()
      }
    },
    computedTextSync () {
      if (!this.isSync) {
        return this.$t('t.ToSynchronizeWithAr24')
      }
      if (!this.isConfirmed) {
        return this.$t('t.ToConfirmedAccountAr24')
      }
      return null
    },
    computedZipcode: {
      get () {
        return this.dataZipcode
      },
      set (v) {
        this.dataZipcode = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        ty: 'ar24',
        v: {
          address1: this.dataAddress1,
          address2: this.dataAddress2,
          city: this.dataCity,
          cgu: this.document?.v?.cgu ?? false,
          companySiret: this.dataCompanySiret,
          companyTva: this.dataCompanyTva,
          company: this.dataCompany,
          confirmed: this.document?.v?.confirmed ?? false,
          country: this.dataCountry,
          email: this.dataEmail,
          idUser: this.dataIdUser,
          isSync: this.document?.v?.isSync ?? false,
          firstname: this.dataFirstname,
          lastname: this.dataLastname,
          notify: this.dataNotify,
          zipcode: this.dataZipcode
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async syncUser () {
      this.isWorking = true
      await this.$http().post(`/core/providers/${this.$route.params.id}/ar24/sync_user`).then(() => {
        this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.SyncCompleted') }))
      })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response?.data?.message)
        })
        .finally(() => {
          this.isWorking = false
          this.$emit('refresh')
        })
    },
    async resendConfirmationEmail () {
      this.isWorking = true
      await this.$http().post(`/core/providers/${this.$route.params.id}/ar24/resend_confirmation_email`).then(() => {
        this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.ResendConfirmationEmailCompleted') }))
      })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response?.data?.message)
        })
        .finally(() => {
          this.isWorking = false
        })
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    document: Object
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataAddress1 = v?.address1
        this.dataAddress2 = v?.address2
        this.dataCity = v?.city
        this.dataCompany = v?.company
        this.dataCompanySiret = v?.companySiret
        this.dataCompanyTva = v?.companyTva
        this.dataCountry = v?.country
        this.dataEmail = v?.email
        this.dataFirstname = v?.firstname
        this.dataLastname = v?.lastname
        this.dataNotify = v?.notify ?? false
        this.dataIdUser = v?.idUser ? v?.idUser : null
        this.dataZipcode = v?.zipcode
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.field
  max-width 30%

.connect-btn
  max-width 300px
</style>
